import {LitElement, html, css} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';

/**
Youtube Embed Lit Element
@author Zach Inglis
@version 1.0
@link http://zachinglis.com

@cssprop --cover-background
@cssprop --cover-background-size
@cssprop --paused-background
@cssprop --paused-background-size
@cssprop --ended-background
@cssprop --ended-background-size

 * Loosely based on: https://maxl.us/hide-related
 */

type VideoState = 'UNSTARTED' | 'ENDED' | 'PLAYING' | 'PAUSED' | 'BUFFERING' | 'CUED';

/**
  * Embed a Youtube video anywhere.
  * @attribute {String} id - The id of the Youtube video you want to display.
  */
@customElement('youtube-embed')
export class YoutubeEmbed extends LitElement {
  /**
   * You can control the output via setting the CSS vars:
   * `--cover-background`, `--paused-background`, `--ended-background`,
   *
   * `--cover-background` by itself will propogate the other variables.
   * All variables are intentionally via a background attribute, so you can
   * overwrite as needed.
   */
  static styles = css`
    :host {
      display: inline-block;
      position: relative;
      width: 100%;

      --cover-background: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEiIHdpZHRoPSIxNzA2LjY2NyIgaGVpZ2h0PSIxNzA2LjY2NyIgdmlld0JveD0iMCAwIDEyODAgMTI4MCI+PHBhdGggZD0iTTE1Ny42MzUgMi45ODRMMTI2MC45NzkgNjQwIDE1Ny42MzUgMTI3Ny4wMTZ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
      --cover-background-2x: var(--cover-background);
      --cover-background-size: 101%;
      --paused-background: var(--cover-background);
      --paused-background-2x: var(--cover-background-2x);
      --paused-background-size: var(--cover-background-size);
      --ended-background: var(--cover-background);
      --ended-background-2x: var(--cover-background-2x);
      --ended-background-size: var(--cover-background-size);
    }

    .wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
    }

    .wrapper:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: 0;
      right: 0;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      pointer-events: none;
      transition: opacity 0.2s;

      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: var(--cover-background-size);
      background-image: var(--cover-background);
    }

    @media only screen and (min-width: 1440px) {
      .wrapper:after {
        background-image: var(--cover-background-2x);
      }
    }

    .wrapper.PLAYING:after, .wrapper.BUFFERING:after {
      opacity: 0;
    }

    .wrapper.ENDED:after {
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: var(--ended-background-size);
      background-image: var(--ended-background);
    }

    @media only screen and (min-width: 1440px) {
      .wrapper.ENDED:after {
        background-image: var(--ended-background-2x);
      }
    }

    .wrapper.PAUSED:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      bottom: 0;
      right: 0;
      cursor: pointer;
      pointer-events: auto;
      background-color: black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: var(--paused-background-size);
      background-image: var(--paused-background);
    }

    @media only screen and (min-width: 1440px) {
      .wrapper.PAUSED:after {
        background-image: var(--paused-background-2x);
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  `

  /**
   * The ID of the video you want to play.
   * @example h0Oe6QMlxgU
   *
   * This is required. It will not work without it.
   */
  @property()
  video = '';

  /**
   * Video width
   */
  @property({ type: Number })
  width = 640;

  /**
   * Video height
   */
  @property({ type: Number })
  height = 360;

  /**
   * Allow the video to be paused.
   * If false, will autoplay the video after every pause.
   */
  @property({ type: Boolean })
  allowPause = true;

  /**
   * Continuously loop the video
   */
  @property({ type: Boolean })
  loop = false;

  /**
   * Continuously loop the video
   */
  @property({ type: Boolean })
  stayOnVideo = true;

  /**
   * State of the video
   * @enum {VideoState}
   */
  @property({ attribute: true, reflect: true })
  state: VideoState = 'UNSTARTED';

  @property({ attribute: false })
  player!: YT.Player | null;

  @query('iframe')
  iframe!: HTMLIFrameElement | null;

  play() {
    this.player?.playVideo()
  }

  pause() {
    this.player?.pauseVideo()
  }

  stop() {
    this.player?.stopVideo()
  }

  rewindToStart() {
    this.player?.seekTo(0, true)
  }

  onReady() {}

  setPlayerState(playerState: YT.PlayerState) {
    if (playerState === YT.PlayerState.UNSTARTED) {
      this.state = 'UNSTARTED'
    }
    if (playerState === YT.PlayerState.ENDED) {
      this.state = 'ENDED'
    }
    if (playerState === YT.PlayerState.PLAYING) {
      this.state = 'PLAYING'
    }
    if (playerState === YT.PlayerState.PAUSED) {
      this.state = 'PAUSED'
    }
    if (playerState === YT.PlayerState.BUFFERING) {
      this.state = 'BUFFERING'
    }

    // Allow for hooks
    const event = new CustomEvent('state-changed', {
      detail: YT.PlayerState[playerState]
    })
    this.dispatchEvent(event);
  }

  onPaused() {
    if(!this.allowPause)
      this.play()
  }

  onEnded() {
    if(this.loop)
      this.rewindToStart()
    if (this.stayOnVideo) {
      this.rewindToStart()
      this.pause()
    }
  }

  onPlayerStateChange({ data }: YT.OnStateChangeEvent) {
    this.setPlayerState(data)

    if (data === YT.PlayerState.ENDED)
      this.onEnded()

    if (data === YT.PlayerState.PAUSED)
      this.onPaused()
  }

  onScriptLoad() {
    window.YT.ready(() => {
      if (!this.player) {
        this.player = new YT.Player(this.iframe as HTMLElement, {
          events: {
            onReady: this.onReady.bind(this),
            onStateChange: this.onPlayerStateChange.bind(this)
          },
        });
      }
    })
  }

//#region Template Helpers
  script() {
    let tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    tag.onload = this.onScriptLoad.bind(this);
    return tag
  }

  togglePause(e: Event) {
    console.log("CLEEK", this.state)
    if (this.state === 'PLAYING')
      this.pause()
    else
      this.play()
  }
//#endregion

  protected render() {
    if (!this.video) return html`<!-- Video ID is required -->`;

    return html`
      <div class="wrapper ${this.state}"
          @click="${this.togglePause}">
        <iframe
          width="${this.width}"
          height="${this.height}"
          src="https://www.youtube.com/embed/${this.video}?rel=0&enablejsapi=1"
          frameborder="0"
        >
        </iframe>
      </div>

      ${this.script()}
    `
  }
}